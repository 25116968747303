import React from 'react';
import LeftPannelAgent from './LeftPanelAgent';


const LeftPannelAgents = () => {


    return (
        <div className='left-pannel-team-container'>
            <LeftPannelAgent agentName = "HaikuMaster" />
            <LeftPannelAgent agentName = "PostGenie" />
            <LeftPannelAgent agentName = "MelodyMaker" />
            <LeftPannelAgent agentName = "VideoWhiz" />
            <LeftPannelAgent agentName = "ArtMeme" />
            <LeftPannelAgent agentName = "SceneDirector" />
            <LeftPannelAgent agentName = "SoundScribe" />
            <LeftPannelAgent agentName = "PromptMaster" />

        </div>
    );
};

export default LeftPannelAgents;