import { PDFViewer } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import MyDocument from "./ReportPDF";
import ReportPDF from "./ReportPDF";

function Summary() {

    const [documentPath, setDocumentPath] = useState("")
    const [waitOver, setWaitOver] = useState(false)


    useEffect(() => {
        setTimeout(() => {
            setWaitOver(true)
            
        
        }, 2000)
    },[])

    useEffect(() => {
        if(document.getElementById("summary-card")){
        document.getElementById("summary-card").scrollIntoView({block: "start"})
    }

    }, [waitOver])



    const downloadReport = () => {
        setDocumentPath("./generated_documents/report.pdf")
    }

    const downloadTranscript = () => {
        setDocumentPath("./generated_documents/transcript.pdf")

    }

    return (
        <>
            <div className="summary-container" id="summary-container">
                <div className="manager-message">
                    <div className="manager-message-content">
                        <p>Done! Here is a summary of the team’s work.</p>
                    </div>
                </div>

                {waitOver && (

                <div className="summary-card" id = "summary-card">
                    <div className="summary-card-header">Conclusion</div>
                    <div className="summary-card-content">
                        <p>
                            It looks like we've covered all the bases for a successful and entertaining campaign.
                            If there are no further changes or additions, we're ready to roll out this creative content.
                        </p>

                        <p>
                        The "Walmart Price Match Saga" campaign effectively combines humor, sarcasm, and relatability to engage audiences and spark conversations about retail experiences.
                        </p>

                        <p>
                        By utilizing a variety of digital media formats, the campaign is designed to reach a broad audience and encourage sharing and interaction.
                        </p>

                        <p>
                        This creative approach not only highlights the specific experience but also resonates with common consumer frustrations, making it a relatable and entertaining narrative. 
                        </p>
                        <br/>


                        <div className="summary-card-image-row">
                            <div className="text-content">
                                <p><strong>Viral Social Media Post: #WalmartFail</strong></p>
                                <p>🚨 BREAKING: Walmart's Price Match Mystery! 🚨</p>
                                <p>🎭 Imagine this: You walk into Walmart, eyes set on a Lego set for your kid. Price online? 35% cheaper than the in-store tag. You ask for a price match, and BOOM... you're hit with the "we don't compete with ourselves" line! 🤯</p>
                                <p>So, you do the logical thing: buy it online for in-store pickup. As you wait, you watch an employee pick your prized Lego set off the shelf you were just at. The catch? You can't take it home until the next day. 🤦‍♂️</p>
                                <p>It's official: Customer service level = Walmart. Where price matching is just a myth spun in the aisles. 🤡</p>
                                <p>#WalmartFail #PriceMatchFail #CustomerServiceSaga #RetailWoes #LegoQuest</p>
                            </div>
                            <div className="media-row">
                                <img src="./images/PromptMaster1.png" />
                                <img src="./images/PromptMaster2.png" />
                                <video width="320" height="240" controls>
                                    <source src="./images/walmart_music.mp4" type="video/mp4" />
                                    Your browser does not support this media.
                                </video>
                                <video width="320" height="240" controls>
                                    <source src="./images/walmart_tiktok.mp4" type="video/mp4" />
                                    Your browser does not support this media.
                                </video>
                            </div>
                        </div>
                    </div>

                    <div className="summary-buttons-container">
                        <button onClick={downloadReport}>Download report</button>
                        <button onClick={downloadTranscript}>Download full transcript</button>

                    </div>

                    {documentPath && <ReportPDF documentPath={documentPath} setDocumentPath={setDocumentPath} />}
                </div>
                )}
            </div>


        </>
    )
}

export default Summary;