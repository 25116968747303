import React, { useEffect, useState } from "react";
import './App.css';
import AgentsChat from './Components/AgentsChat/AgentsChat';
import Chat from './Components/Chat/Chat';
import LeftPannel from './Components/LeftPannel/LeftPannel';
import Summary from "./Components/Summary/Summary";
import trailingIcon from './static/TrailingIcon.png';


function App() {

  const [leftPannelContent, setLeftPannelContent] = useState(["No info provided yet", false, false])

  const closeLandingPage = () => {
    document.getElementById('landingPage').classList.toggle('landing-page-closed')

};


  return (
    <>

      <div className='landing-page' id="landingPage">

        <div className='landing-column-one'></div>
        <div className='landing-column-two'><div className='introText'><p>For many people, the pursuit of health, wealth and happiness feels slow and tiresome. The power of the crowd—and its strong, person-to-person affinity—is satisfying their impatience for new avenues and mindsets on how to navigate the pillars of a fulfilling life.</p>
<p>In this R&D experiment, you will discover the next generation of tools that will empower the impatience economy and make people feel superhuman.</p>

<button className="close-landing-page" onClick={closeLandingPage}>Start the Experience <img src={trailingIcon} alt="" /></button>
</div>
</div>
        <div className='landing-column-three'></div>
        
        

      </div>
      <div className='main-container'>
        <LeftPannel leftPannelContent={leftPannelContent} />
        <Chat leftPannelContent={leftPannelContent} setLeftPannelContent={setLeftPannelContent} />
      </div>
      

    </>

  );
}

export default App;
