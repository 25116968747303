import React, { useEffect, useRef, useState } from "react";
import chatHistory from '../../static/chatHistory.json'
import Summary from "../Summary/Summary";


const AgentsChat = () => {
    const [messages, setMessages] = useState([]);
    let chatHistoryArray = chatHistory["chatHistory"]
    const [agentsChatFinished, setAgentsChatFinished] = useState(false);
    const [index, setIndex] = useState(0)
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    };
  
    useEffect(() => {
      scrollToBottom();
    }, [messages]);
  
    useEffect(() => {
      if (index < chatHistoryArray.length) {
        const loadMessages = setInterval(() => {
          setMessages((prevMessages) => [...prevMessages, chatHistoryArray[index]]);
          setIndex((prevIndex) => prevIndex + 1);
          if (index === chatHistoryArray.length - 1) {
            clearInterval(loadMessages);
            setAgentsChatFinished(true);
          }
        }, 1000);
        return () => clearInterval(loadMessages);
      }
    }, [index]);

    return (
        <>
            <div className="agents-messages-container" id="agents-messages-container">
                <div className="line-with-text">
                    <hr />
                    <span>Agents chat room</span>
                    <hr />
                </div>
                {messages.map((message, i) => {
                    if (!message || !message.name) {
                        return null;
                    }

                    if (message.content === "image_file") {
                        return (
                            <div className={message.name === "manager" ? "manager-message" : "message"} key={i}>
                                {message.name !== "manager" && (
                                    <img src={`./agent_thumbnails/${message.name}.png`} alt={`${message.name}`} />
                                )}
                                <div>
                                    {message.image_url.map((src, image_index) => (
                                        <div className={message.name === "manager" ? "manager-message-content" : "message-content"} key={image_index}>
                                            <img src={src} alt={`Image ${image_index}`} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        );
                    }
                    if (message.content === "video_file") {
                        return (
                            <div className={message.name === "manager" ? "manager-message" : "message"} key={i}>
                                {message.name !== "manager" && (
                                    <img src={`./agent_thumbnails/${message.name}.png`} alt={`${message.name}`} />
                                )}
                                <div>
                                    {message.image_url.map((src, image_index) => (
                                        <div className={message.name === "manager" ? "manager-message-content" : "message-content"} key={image_index}>
                                            <video
                                                src={src}
                                                controls
                                                alt={`Video ${image_index}`}
                                                style={{ height: '250px', width: 'auto', objectFit: 'contain' }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        );
                    }
                    if (message.content === "audio_file") {
                        return (
                            <div className={message.name === "manager" ? "manager-message" : "message"} key={i}>
                                {message.name !== "manager" && (
                                    <img src={`./agent_thumbnails/${message.name}.png`} alt={`${message.name}`} />
                                )}
                                <div>
                                    {message.image_url.map((src, image_index) => (
                                        <div className={message.name === "manager" ? "manager-message-content" : "message-content"} key={image_index}>
                                            <audio controls src={src} alt={`Audio ${image_index}`} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        );
                    }

                    if (message.name === "manager") {
                        return (
                            <div className="manager-message" key={i}>
                                {message.content.split("\n\n").map((paragraph, index) => (
                                    <div className="manager-message-content" key={index}>
                                        <p>{paragraph}</p>
                                    </div>
                                ))}
                                {message.image_url && message.image_url.length > 0 && (
                                    <div className="manager-message-content">
                                        {message.image_url.map((src, image_index) => (
                                            <img src={src} alt={`Manager Image ${image_index}`} key={image_index} />
                                        ))}
                                    </div>
                                )}
                            </div>
                        );
                    }

                    return (
                        <div className="message" key={i}>
                            <img src={`./agent_thumbnails/${message.name}.png`} alt={`${message.name}`} />
                            <div>
                                {message.content.split("\n\n").map((paragraph, index) => (
                                    <div className="message-content" key={index}>
                                        <p>{paragraph}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    );
                })}

<div ref={messagesEndRef} />

                {agentsChatFinished &&
                    (
                        <div className="final-block-container">
                            <div className="final-line-with-text">
                                <hr />
                                <span>End of agents chat</span>
                                <hr />
                            </div>
                            <Summary />
                        </div>
                    )
                }
            </div>
        </>
    );
};

export default AgentsChat;