import React, { useEffect, useState } from "react";
import chatWithUserAgent from '../../static/chatWithUserAgent.json'
import axios from "axios";
import MessageList from "../MessageList/MessageList";
import sales_agent_logo from "../../static/groupsales_agent.png"
import human_user_logo from "../../static/human_user.png"
import Modal from "../Modal/Modal";
import LoadingDots from "../Loading/LoadingDots";
import Agents from "./Agents";
import AgentsChat from "../AgentsChat/AgentsChat";
import image2 from "../../static/image_2.png"
import pdf from "../../static/pdf.png"



function Chat({ leftPannelContent, setLeftPannelContent }) {

    const [messages, setMessages] = useState([chatWithUserAgent["chatWithUserAgent"][0]])
    const [userInput, setUserInput] = useState("")
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [isFinalMessageVisible, setIsFinalMessageVisible] = useState(false)
    const [isAgentsChatVisible, setIsAgentsChatVisible] = useState(false)

    useEffect(() => {
        document.getElementById('messages-container').lastChild.scrollIntoView({ block: "end" })
    })

    const handleButtonClick = () => {
        setUserInput('write a poem to be posted on X to make fun of a company based on my negative experience. Complement this with a video to be posted on TikTok. Also generate a song with lyrics inspired from my story, as well as an image for the thumbnail.');

    };
    const handleButtonClick2 = () => {
        setUserInput('I need help optimizing a campaign for a beauty product');


    };

    const handleSendButton = () => {
        // Check if the input is not empty before adding to messages
        if (userInput.trim()) {
            // Create a new message object
            const newMessage = {
                role: "user", // Simple ID generation
                message: userInput,
            };

            setUserInput('')
            setMessages([...messages, newMessage]);
            setIsLoading(true)
            setLeftPannelContent((leftPannelContent) => [
                ["Poem based on feedbacks",],
                ...leftPannelContent.slice(1)
            ])
            // document.getElementById('left-pannel').style.visibility = 'visible'
            document.getElementById('left-pannel').classList.toggle('left-pannel-slide')
            document.getElementById('previous-project-container').style.visibility = 'hidden'
            document.getElementById('animation-gif').style.display = 'none'
            // document.getElementById('animation-gif').classList.toggle("#animation-gif:after");



            setTimeout(() => {
                const newMessage = {
                    role: "assistant", // Simple ID generation
                    message: 'Sure. Could you upload any useful information about your feedbacks?',
                };

                setMessages((prevMessages) => [...prevMessages, newMessage]);
                setIsLoading(false)


            }, 2000);


        }


    }

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleStartButton = () => {
        setIsFinalMessageVisible(true)
        setIsLoading(true)
        setTimeout(() => {
            setIsAgentsChatVisible(true)
            setIsLoading(false)

        }, 2000)


    };



    return (
        <>

            <div className="chat" id="chat">
                <img src="./loading_animation/agent orchestra_1.gif" id="animation-gif" />

                <div className="messages-container" id="messages-container">

                    {messages.map(message => {
                        {
                            if (message.role == "assistant") return (
                                <div className="message">
                                    <div className="message-content-assistant">
                                        {message.message}
                                    </div>
                                </div>
                            )
                        }
                        {
                            if (message.role == "user") return (
                                <div className="message message-with-icon">

                                    <div className="message-content-user">
                                        {message.message}
                                    </div>
                                    <img src={human_user_logo}></img>
                                </div>
                            )
                        }
                    }
                    )}

                    
                    {leftPannelContent[1] && (
                        <>
                            <div className="message message-with-icon">
                                <div className="message-content-user">

                                    <div className="image-row-user-message">
                                        <div className="image-with-title-message">
                                            <img src={pdf}></img>
                                            <p>Customer_feedbacks.pdf</p>
                                        </div>
                                    </div>
                                </div>
                                <img src={human_user_logo}></img>
                            </div>
                            <Agents leftPannelContent={leftPannelContent} setLeftPannelContent={setLeftPannelContent} setIsLoading={setIsLoading} isLoading={isLoading}/>
                        </>
                    )}


                    {leftPannelContent[2] && (
                        <div>

                            <div className="message">Noted. <br />Would you like to participate in the conversation?</div>
                            <button className="start-agent-conversation-button">Yes</button>
                            <button className="start-agent-conversation-button" onClick={handleStartButton}>No</button>
                            {isFinalMessageVisible && (
                                <div className="message" >Alright! I will brief the team members, you can sit back and watch our chat here.</div>
                            )
                            }
                            {isAgentsChatVisible && (
                                <AgentsChat />
                            )
                            }
                        </div>
                    )}
                    {isLoading && (<LoadingDots />)}
                </div>

                <div className="user-input-container">
                    <input
                        className="user-input"
                        type="text"
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)} >

                    </input>
                    <button className="upload-button" onClick={openModal}>
                    <svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.66667 4.9L5.66667 19.4667C5.66667 20.7553 6.71134 21.8 8 21.8V21.8C9.28866 21.8 10.3333 20.7553 10.3333 19.4667L10.3333 5.66667C10.3333 3.08934 8.244 1 5.66667 1V1C3.08934 1 1 3.08934 1 5.66667L1 20C1 23.866 4.13401 27 8 27V27C11.866 27 15 23.866 15 20L15 4.9" stroke="#A7A7A7" stroke-width="1.2"/>
                    </svg>
                    </button>
                    <button className="send-button" onClick={handleSendButton}>
                    <svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 25V0L27 12.4991L0 25ZM1.09459 23.2143L24.8108 12.4991L1.09459 1.78571L1.45946 9.64286L12.2758 12.4991L1.45946 15.3571L1.09459 23.2143Z" fill="#A7A7A7"/>
                    </svg>
                    </button>
                </div>
                <div className="previous-project-container" id="previous-project-container">
                    <p className="previous-project">Previous project</p>
                    <button className="product-acceptance-button" onClick={handleButtonClick}>Design a poem based on feedbacks</button>
                </div>
            </div>

            {isModalOpen && <Modal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} leftPannelContent={leftPannelContent} setLeftPannelContent={setLeftPannelContent} setIsLoading={setIsLoading} />}

        </>
    )
}


export default Chat;